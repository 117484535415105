// useSEO.js
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function UseSEO({ title, description, image, url, type = 'website' }) {
  useEffect(() => {
    // Update the document title
    if (title) {
      document.title = title;
    }
  }, [title]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph meta tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url || window.location.href} />
      <meta property="og:type" content={type} />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

export default UseSEO;
