import React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import AdminNav from './adminNav';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TextField,Button } from '@mui/material';
import axios from 'axios';
import noData from './no-data.png'


const style = {
    position: 'absolute',
    top: '0',
    right: '0',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    height:'100%',
    overflow: 'scroll',
    paddingBottum: 2
  };


export default function AdminAndSales() {
    const [open, setOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [jobTitle, setJobTitle] = useState(['front end developer']);
  const [rows,setRows]=useState([])
  const handleCheckboxChange = (event) => {
    setIsAdmin(event.target.checked);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  function getAllUsers(params) {
    axios
    .post(`/getUsers`,{
      type: '1'
    })
    .then((result) => {
      console.log('result',result);
        setRows(result.data.Users)
    })
    .catch((err) => {
        // setMessage("No Category found")
    });
  }
  useEffect(() => {
    getAllUsers()
  }, []);
  function addNewUser() {
    axios
    .post(`signUp`,{
      userName: userName,
      email: email,
      password: password,
      phone:phone,
      admin:isAdmin,
    })
    .then((result) => {
      getAllUsers()
      handleClose()
    })
    .catch((err) => {
        // setMessage("No Category found")
    });
  }
  function deleteUser(id) {
    axios
    .delete(`/deleteUser/${id}`)
    .then((result) => {
      getAllUsers()
    })
    .catch((err) => {
        // setMessage("No Category found")
    });
   
    
  }
  return (
    <>
    <AdminNav />
    <Container className='' maxWidth="xl">
    
      <div className='title-with-search'>
        <h2>Company Cards</h2>
        <Button onClick={handleOpen}  variant="contained">Add New Team Member</Button>
      </div>
      { rows.length == 0 ?
      <div className='image-no-data-div'>
     <img src={noData} alt="noData image" />
     </div>
      :
      <>
    
    <TableContainer component={Paper}>
       
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>phone</TableCell>
            <TableCell>Delete User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >{row.userName}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell ><DeleteIcon onClick={() => deleteUser(row._id)} className='cancel-icon' /></TableCell>
              {/* <BorderColorIcon onClick={() => handleOpen(row)} />
               */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>}
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" className='edit-user-info-header' variant="h6" component="h2">
              Add New User
            </Typography>
            <TextField
          required
          id="outlined-required"
          label="User Name"
          defaultValue={userName}
          className='input-edit-default'
          onChange={(e) => {
            setUserName(e.target.value);
        }}
        />
        <TextField
          required
          id="outlined3"
          label="Email"
          name='Email'
          defaultValue={email}
          className='input-edit-default'
          onChange={(e) => {
            setEmail(e.target.value);
        }}
        />
        <TextField
          required
          id="outlined3"
          label="Passowrd"
          name='Passowrd'
          type='password'
          defaultValue={password}
          className='input-edit-default'
          onChange={(e) => {
            setPassword(e.target.value);
        }}
        />
        <TextField
          required
          id="outlined3"
          label="Phone"
          name='Phone'
          defaultValue={phone}
          className='input-edit-default'
          onChange={(e) => {
            setPhone(e.target.value);
        }}
        />
        <label className='adminCheckBox'>
        Is Admin ?
        <input
          type="checkbox"
          checked={isAdmin}
          onChange={handleCheckboxChange}
        />
        
      </label>
      <Button onClick={addNewUser} className=' edit-user-btn' variant="contained">Add User</Button>

         
        
          </Box>
        </Fade>
      </Modal>
    </Container>
    </>
  );
}