import React, { useState, useRef } from 'react';
import  upload  from './upload.svg';


const FileUpload = (props) => {
  const fileInputRef = useRef(null); // Reference to the hidden input

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      props.setFile(selectedFile);
    } else {
      alert('Please select a valid PDF file.');
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click(); 
  };

  return (
    <div>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <div
        onClick={triggerFileInput}
        className='upload-section'
      >
        <p> {props.file != null ? 'File uploaded successful':"tap here to Upload PDF"}</p>
        {props.file != null && <p> {props.file.name} </p>}
        { props.file == null && <img src={upload} />}
      </div>
    </div>
  );
};

export default FileUpload;
