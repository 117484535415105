// PdfViewer.js
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useState,useEffect } from 'react';
import {useSearchParams } from 'react-router-dom';
import axios from 'axios';

export default function ShowPdfFile() {
    const [data ,setData]=useState({})
  const [loading ,setLoading]=useState(0)
  const[searchParams] = useSearchParams()
  const[id,setId] = useState('')
  const[color,setColor] = useState('')

  
  

  function getUseInfo(id1) {
    
    axios.post('/get_Order_by_id', {id:id1})
    .then((result) => {
      console.log('result.data.order',result.data.order);
      setData(result.data.order)
      setColor(result.data.order.color)
      setLoading(1)
    })
    .catch((error) => {
      console.error(error);
    });
    
  }
  useEffect(()=>{
    getIdValue()
  },[])
  async function getIdValue(params) {
    await setId(searchParams.get('id'))
    getUseInfo(searchParams.get('id'))
  }
    const defaultLayout = defaultLayoutPlugin();

    return (
        <>
        { loading == 0 ?<div>
            <div class="spinner-border text-secondary" role="status">
  <span class="sr-only"></span>
</div>
        </div>
        :
        <div style={{ height: '100vh', width: '100%' }}>
           <Worker  style={{width: '100%' }} workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
    <Viewer fileUrl={data.pdfFile} />
</Worker>
        </div>}
        </>
    );
}

// export default ShowPdfFile;
