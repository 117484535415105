import React from "react";
import vCardsJS from "vcards-js";
import axios from "axios";

export default function SaveContact(props) {
  console.log("props.data", props.data);

  // const downloadTxtFile = (vcfText) => {
  //   const element = document.createElement("a");
  //   const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "myContact.vcf";
  //   document.body.appendChild(element);
  //   element.click();
  // };
  const downloadTxtFile = (vcfText) => {
    // Encode the vCard text as Base64
    const base64Data = btoa(vcfText);
    const dataUrl = `data:text/vcard;base64,${base64Data}`;
  
    // Create an anchor element and set its href to the data URL
    const element = document.createElement("a");
    element.href = dataUrl;
    element.download = "myContact.vcf";
  
    // Append the element to the DOM, trigger the download, and remove it
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const getBase64FromUrl = async (url) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result.split(",")[1]; // Extract the base64 part
        resolve(base64data);
      };
    });
  };

  const CreateVCard = async () => {
    
    const vCard = vCardsJS();
    const vCardString = vCard.getFormattedString();
    let fullName = props.data.fullName.split(" ");
    let company = props.data?.company;
    let department = props.data?.department;

    vCard.firstName = fullName[0];
    vCard.lastName = fullName[fullName.length - 1];
    vCard.organization = company;
    vCard.email = props.data?.email[0]?.link;
    vCard.workPhone = props.data?.mobileNumber[0]?.link;
    vCard.title = department;
    vCard.url = props.data?.website[0]?.link;
    const facebookLink = props.data?.facebook?.[0]?.link;
    const instagramLink = props.data?.instgram?.[0]?.link;
    // vCard.socialUrls["FACEBOOK"] = "https://facebook.com/example"; 
    // vCard.socialUrls["INSTAGRAM"] = "https://instagram.com/example"; 
  
  //   if (facebookLink) {
  //     vCard.socialUrls["facebook"] = encodeURIComponent(facebookLink);
  // }else{
  //   console.log('facebookLink')
  // }

  // if (instagramLink) {
  //     vCard.socialUrls["instagram"] = encodeURIComponent(instagramLink);
  // } else{
  //   console.log('instagram')
  // }

    // Fetch and embed the photo
    try {
      const base64Img = await getBase64FromUrl(props.data.mainUserImg);
      vCard.photo.embedFromString(base64Img, "JPEG");
    } catch (error) {
      console.error("Error embedding the image", error);
    }

    return vCard.getFormattedString();
  };

  return (
    <button
      style={{ backgroundColor: props.color }}
      onClick={async () => downloadTxtFile(await CreateVCard())}
      className="save-button"
    >
      SAVE CONTACT
    </button>
  );
}
