import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
// import { redirect } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import Home from './components/home/Home';
// import SignUp from './components/signUp/signUp'
import Login from './components/signIn/signIn';
import Product from './components/product/product';
import AddItem from './components/addItem/addItem';
import { userContext } from './contexts/userContext';
import UploadImage from './components/firebase/img';
import Payment from './components/payment/Payment';
import Admin from './components/admin/admin';
import MenuOrders from './components/admin/MenuOrders';
import BasicCard from './components/admin/BasicCard';
import CompanyCard from './components/admin/companyCard'
import UserProfile from './components/userProfile/userProfile';
import CreateOrder from "./components/createOrder/createOrder";
import AdminAndSales from "./components/admin/adminAndSales";
import ChoseType from "./components/choseType/type";
import ShowPdfFile from './components/showPdf';

import jwt from 'jwt-decode';



function App() {
  const navigate = useNavigate();
  let t = localStorage.getItem("auth-token");


  const [token, setToken] = useState(t);
  const [userId, setUserId] = useState(false);
  const [admin, setAdmin] = useState(false);
 
  const loggedRoutes = <Routes>
    <Route path='/' element={<Login />} />
    <Route path='/UserProfile' element={<UserProfile />} />
    {/* <Route path='/MenuOrders' element={<MenuOrders />} />
    <Route path='/BasicCard' element={<BasicCard />} />
    <Route path='/CompanyCard' element={<CompanyCard />} />
    <Route path='/AdminAndSales' element={<AdminAndSales />} /> */}
    <Route path='/ChoseType' element={<ChoseType />} /> 
    <Route path='/ShowPdfFile' element={<ShowPdfFile />} />
    
  </Routes>;
  const adminRoutes = <Routes>
   <Route path='/' element={<Login />} />
    <Route path='/UserProfile' element={<UserProfile />} />
    <Route path='/MenuOrders' element={<MenuOrders />} />
    <Route path='/BasicCard' element={<BasicCard />} />
    <Route path='/CompanyCard' element={<CompanyCard />} />
    <Route path='/AdminAndSales' element={<AdminAndSales />} />
    <Route path='/ChoseType' element={<ChoseType />} /> 
    <Route path='/ShowPdfFile' element={<ShowPdfFile />} />
    
    
  </Routes>;
  const regroute = <Routes>
    <Route path='/' element={<Login />} />
    <Route path='/UserProfile' element={<UserProfile />} />
    <Route path='/ShowPdfFile' element={<ShowPdfFile />} />
    
    {/* <Route path='/' element={<Login />} />
    <Route path='/UserProfile' element={<UserProfile />} />
    <Route path='/MenuOrders' element={<MenuOrders />} />
    <Route path='/BasicCard' element={<BasicCard />} />
    <Route path='/CompanyCard' element={<CompanyCard />} />
    <Route path='/AdminAndSales' element={<AdminAndSales />} />
    <Route path='/ChoseType' element={<ChoseType />} />  */}
    
  </Routes>;
  const [routes, setRoutes] = useState(regroute);

  const login = useCallback((uid, token) => {
    setToken(token);
    setUserId(uid);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setAdmin(null);
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("auth-token");
    console.log("token",token);
    if (token === null) {
      localStorage.setItem("auth-token", "");
      token = "";
      setToken();
      setRoutes(regroute);
    }
    else if (token) {
      const user = jwt(token);
       if (user.admin) {
        setAdmin(true);
        console.log('adminRoutes',adminRoutes);
        setRoutes(adminRoutes);

      }
      else {
        setAdmin(false);
        setRoutes(loggedRoutes);
      }
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const bodyParameters = {
      key: "value"
    };
    console.log(token);
    // Axios.post('/isValid', bodyParameters, config).then(result => {
    //   console.log(result);
    //   if (result.data.token === false) {
    //     localStorage.setItem("auth-token", "");
    //     setToken(false);
    //   }

    // })
  }, []);

  return (
    <userContext.Provider value={
      {
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout
      }
    }>

{/* <h1>tesrt</h1> */}

      {routes}

    </userContext.Provider>
  );
}

export default App;


