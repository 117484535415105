import React from 'react';
import { useRef, useState } from 'react';
import Container from '@mui/material/Container';
import CreateOrder from "../createOrder/createOrder"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function ChoseType() {
    const [selectedType , setSelectedType]=useState(0)
    const [next , setNext]=useState(false);
    function nextFunction(params) {
        setNext(true)
    }
    const handleChange = (event) => {
        console.log('event.target.value-->', parseInt(event.target.value, 10));
        setSelectedType( parseInt(event.target.value, 10));
      };

  return (
    <>
    {next ?
    <CreateOrder selectedType={selectedType} setNext={setNext} />:
    <div className='create-order-div'>
    <Container className='main-container-div' >
      <div className='header-add-order'>
        <div>
        <p>chose the type of card</p> 
        </div>
        <a className='pointer' onClick={() => selectedType != 0 && nextFunction()}>Next</a>
        {/* <img src={BackBtn}  alt="Back Icon" /> */}
       
      </div>
      <div className='selet-type' >
      <FormControl>
      <FormLabel className='select-text' id="demo-radio-buttons-group-label">select type of card :</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={selectedType}
      onChange={handleChange}
      >
        <FormControlLabel value="1" control={<Radio />} label="CC Card" />
        <FormControlLabel value="2" control={<Radio />} label="CC Org" />
        <FormControlLabel value="3" control={<Radio />} label="CC Menu" />
      </RadioGroup>
    </FormControl>

      </div>
       
    </Container>
    </div>
    }
    </>
  );
}